var render = function render(){
  var _vm$user, _vm$user2, _vm$user3, _vm$user4;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [!_vm.isEmpty(_vm.getBroadCastMessage) ? _c('div', {
    staticClass: "text-center py-2 white--text",
    class: !_vm.$vuetify.breakpoint.smAndDown ? 'infoBanner ' + _vm.getBroadCastMessage.settings.color : _vm.getBroadCastMessage.settings.color
  }, [_c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.getBroadCastMessage.message)
    }
  }), _vm.getBroadCastMessage.settings.dismissible ? _c('span', {
    staticClass: "text-right float-end right top"
  }, [_c('v-icon', {
    staticClass: "px-3",
    attrs: {
      "color": "offWhite"
    },
    domProps: {
      "textContent": _vm._s('mdi-close-circle')
    },
    on: {
      "click": _vm.dismissBroadcastMessage
    }
  })], 1) : _vm._e()]) : _vm._e(), _c('v-tour', {
    staticStyle: {
      "background-color": "#ffffff",
      "color": "#0067ce"
    },
    attrs: {
      "callbacks": _vm.myCallbacks,
      "options": _vm.tourOptions,
      "steps": _vm.steps,
      "name": "headerTour"
    }
  }), _c('v-app-bar', {
    class: !_vm.isEmpty(_vm.getBroadCastMessage) && !_vm.$vuetify.breakpoint.smAndDown ? 'mt-9' : '',
    attrs: {
      "color": _vm.$vuetify.theme.dark ? '#22272e' : 'white',
      "fixed": !_vm.$vuetify.breakpoint.smAndDown,
      "flat": !_vm.$vuetify.theme.dark,
      "clipped-right": ""
    }
  }, [_vm.$vuetify.breakpoint.mdAndDown && _vm.$route.path !== _vm.pageRoutes.welcome && _vm.$route.path !== _vm.pageRoutes.getStarted ? _c('v-btn', {
    staticClass: "ml-3",
    attrs: {
      "icon": "",
      "large": ""
    },
    on: {
      "click": function click($event) {
        _vm.drawer = !_vm.drawer;
      }
    }
  }, [_c('v-icon', [_vm._v("menu")])], 1) : _vm._e(), _vm.$vuetify.breakpoint.smAndDown && _vm.showMenu ? _c('v-spacer') : _vm._e(), _c('div', {
    staticClass: "cursor-pointer",
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.redirectToHome.apply(null, arguments);
      }
    }
  }, [_c('img', {
    staticClass: "logo-default mx-3",
    attrs: {
      "src": _vm.$vuetify.theme.dark ? '/media/svg/logo-for-dark-theme.svg' : '/media/logos/logo_dark.svg',
      "alt": "TestApp.io",
      "content": "",
      "height": "30"
    }
  })]), _c('v-divider', {
    attrs: {
      "vertical": ""
    }
  }), _vm.showNavigationMenu ? _c('v-btn', {
    staticClass: "iconColor--text text-transform-none text-hover-primary",
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push({
          name: 'dashboard'
        });
      }
    }
  }, [_c('span', {
    staticClass: "darkGrey--text"
  }, [_vm._v(" Dashboard ")])]) : _vm._e(), _vm.showNavigationMenu ? _c('v-btn', {
    staticClass: "iconColor--text text-transform-none text-hover-primary",
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push({
          name: 'team'
        });
      }
    }
  }, [_c('span', {
    staticClass: "darkGrey--text"
  }, [_vm._v(" Team ")])]) : _vm._e(), _vm.showNavigationMenu ? _c('v-btn', {
    staticClass: "iconColor--text text-transform-none text-hover-primary",
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push({
          name: 'apps'
        });
      }
    }
  }, [_c('span', {
    staticClass: "darkGrey--text"
  }, [_vm._v(" Apps ")])]) : _vm._e(), !_vm.showMenu ? _c('v-spacer') : _vm._e(), !_vm.showMenu ? _c('v-spacer') : _vm._e(), _c('v-spacer'), _c('div', {
    staticClass: "nav-menu__icon-links"
  }, [_vm.showNavigationMenu && _vm.getUniqueProgressList ? _c('v-btn', {
    attrs: {
      "id": "upload-btn",
      "icon": ""
    },
    on: {
      "click": _vm.uploadProgress
    }
  }, [_c('v-badge', {
    staticStyle: {
      "z-index": "1"
    },
    attrs: {
      "content": _vm.getUniqueProgressList,
      "color": "success"
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "darkGrey"
    }
  }, [_vm._v("mdi-upload-outline")])], 1)], 1) : _vm._e(), _vm.showNavigationMenu && !_vm.getUniqueProgressList ? _c('v-btn', {
    attrs: {
      "id": "upload-btn",
      "icon": ""
    },
    on: {
      "click": _vm.uploadProgress
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "darkGrey"
    }
  }, [_vm._v("mdi-upload-outline")])], 1) : _vm._e(), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": _vm.openProfileMenu
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "darkGrey",
      "icon": ""
    },
    domProps: {
      "textContent": _vm._s('mdi-account-outline')
    }
  })], 1), _vm.showMenu && _vm.$vuetify.breakpoint.mdAndUp && _vm.showNavigationMenu ? [_vm.totalNotifications ? _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push({
          name: 'user-notification'
        });
      }
    }
  }, [_c('v-badge', {
    staticStyle: {
      "z-index": "1"
    },
    attrs: {
      "content": "".concat(_vm.totalNotifications).includes('-') ? '0' : _vm.totalNotifications,
      "color": "success"
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "darkGrey"
    }
  }, [_vm._v("mdi-bell-outline")])], 1)], 1) : _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push({
          name: 'user-notification'
        });
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "darkGrey"
    },
    domProps: {
      "textContent": _vm._s('mdi-bell-outline')
    }
  })], 1), !_vm.showWhatsNewBadge ? _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": _vm.openWhatsNewList
    }
  }, [_c('v-badge', {
    staticStyle: {
      "z-index": "1"
    },
    attrs: {
      "color": "success",
      "dot": ""
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "darkGrey"
    }
  }, [_vm._v("mdi-newspaper")])], 1)], 1) : _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": _vm.openWhatsNewList
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "darkGrey"
    },
    domProps: {
      "textContent": _vm._s('mdi-newspaper')
    }
  })], 1)] : _vm._e(), _c('v-btn', {
    staticClass: "mx-3",
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.updateDarkTheme();
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "darkGrey"
    },
    domProps: {
      "textContent": _vm._s(_vm.$vuetify.theme.dark ? 'mdi-lightbulb' : 'mdi-lightbulb-on')
    }
  })], 1), _vm.getSocketMessage.show ? _c('v-menu', {
    attrs: {
      "close-on-content-click": false,
      "nudge-bottom": "20",
      "open-on-hover": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on;
        return [_c('span', _vm._g({}, on), [_c('v-icon', {
          staticClass: "mx-2 cursor-pointer",
          attrs: {
            "color": _vm.getSocketMessage.color
          }
        }, [_vm._v(" mdi-wifi ")])], 1)];
      }
    }], null, false, 604216)
  }, [_c('v-card', [_c('v-card-text', [_c('div', {
    staticClass: "bodyFont"
  }, [_vm._v(" " + _vm._s(_vm.getSocketMessage.message) + " ")])]), _vm.getSocketMessage.color === 'error' ? _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    staticClass: "text-transformP-none",
    attrs: {
      "outlined": _vm.$vuetify.theme.dark,
      "color": "primary"
    },
    on: {
      "click": _vm.reloadPage
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    },
    domProps: {
      "textContent": _vm._s('mdi-reload')
    }
  }), _vm._v(" Reload ")], 1), _c('v-spacer')], 1) : _vm._e()], 1)], 1) : _vm._e()], 2)], 1), _c('SidePanel', {
    class: _vm.$vuetify.theme.dark ? 'navigationDark' : 'navigationFont',
    attrs: {
      "height": "100%",
      "right": ""
    },
    model: {
      value: _vm.uploadQueueDialog,
      callback: function callback($$v) {
        _vm.uploadQueueDialog = $$v;
      },
      expression: "uploadQueueDialog"
    }
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-title', [_c('v-spacer'), _c('div', {
    staticClass: "titleFont primary--text"
  }, [_vm._v("Uploads Queue")]), _c('v-spacer')], 1), _c('v-card-text', [_c('feedback-uploading-menu', {
    on: {
      "close": function close($event) {
        _vm.uploadQueueDialog = false;
      }
    }
  })], 1)], 1)], 1), _c('SideNavigationMenu', {
    attrs: {
      "drawer": _vm.drawer
    }
  }), _c('SidePanel', {
    attrs: {
      "permanent": false,
      "temporary": "",
      "width": "600"
    },
    on: {
      "close": function close($event) {
        _vm.showQuickStart = false;
      }
    },
    model: {
      value: _vm.showQuickStart,
      callback: function callback($$v) {
        _vm.showQuickStart = $$v;
      },
      expression: "showQuickStart"
    }
  }, [_c('quick-start-checklist')], 1), _c('SidePanel', {
    attrs: {
      "permanent": "",
      "width": "425"
    },
    on: {
      "close": function close($event) {
        _vm.showProfileDrawer = false;
      }
    },
    model: {
      value: _vm.showProfileDrawer,
      callback: function callback($$v) {
        _vm.showProfileDrawer = $$v;
      },
      expression: "showProfileDrawer"
    }
  }, [_c('v-card', {
    staticClass: "pa-4",
    attrs: {
      "flat": ""
    }
  }, [_c('v-row', [_c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('ProfileImage', {
    attrs: {
      "alt": (_vm$user = _vm.user) === null || _vm$user === void 0 ? void 0 : _vm$user.name,
      "image": (_vm$user2 = _vm.user) === null || _vm$user2 === void 0 ? void 0 : _vm$user2.image,
      "size": _vm.$vuetify.breakpoint.xsOnly ? 80 : 80,
      "className": "img-fluid align-self-center ",
      "from-side-bar": ""
    }
  })], 1), _c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "titleFont darkGrey--text"
  }, [_vm._v(" " + _vm._s((_vm$user3 = _vm.user) === null || _vm$user3 === void 0 ? void 0 : _vm$user3.name) + " ")]), _c('div', {
    staticClass: "darkGrey--text bodyFont"
  }, [_vm._v(" " + _vm._s((_vm$user4 = _vm.user) === null || _vm$user4 === void 0 ? void 0 : _vm$user4.email) + " ")])]), _c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-btn', {
    staticClass: "text-transform-none mr-3",
    attrs: {
      "outlined": _vm.$vuetify.theme.dark,
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        _vm.$router.push({
          name: 'user-settings'
        }), _vm.showProfileDrawer = false;
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": "",
      "small": ""
    }
  }, [_vm._v(" mdi-cogs")]), _vm._v(" Settings ")], 1), _c('v-btn', {
    staticClass: "text-transform-none",
    attrs: {
      "color": _vm.$vuetify.theme.dark ? 'primary' : '',
      "outlined": _vm.$vuetify.theme.dark
    },
    on: {
      "click": _vm.onSIGNOUT
    }
  }, [_c('v-icon', {
    attrs: {
      "left": "",
      "small": ""
    }
  }, [_vm._v("exit_to_app")]), _vm._v(" Sign out ")], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-divider')], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', [_c('v-col', {
    staticClass: "mr-1",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-btn', {
    staticClass: "text-transform-none",
    attrs: {
      "color": "primary",
      "outlined": ""
    },
    on: {
      "click": function click($event) {
        return _vm.openAppDownloadModal('android');
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": "",
      "small": ""
    }
  }, [_vm._v(" mdi-android")]), _c('span', [_vm._v("Android app")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": ""
    }
  }, [_c('v-btn', {
    staticClass: "text-transform-none",
    attrs: {
      "color": "primary",
      "outlined": ""
    },
    on: {
      "click": function click($event) {
        return _vm.openAppDownloadModal('ios');
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": "",
      "small": ""
    }
  }, [_vm._v("mdi-apple")]), _c('span', [_vm._v("iOS app")])], 1)], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-divider')], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-btn', {
    staticClass: "text-transform-none",
    attrs: {
      "block": "",
      "color": "primary",
      "outlined": ""
    },
    on: {
      "click": function click($event) {
        return _vm.openLink('community');
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    },
    domProps: {
      "textContent": _vm._s('mdi-account-group')
    }
  }), _c('span', {
    staticClass: "font14"
  }, [_vm._v(" Join our Community ")])], 1)], 1), _c('v-col', {
    staticClass: "pt-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-btn', {
    staticClass: "text-transform-none",
    attrs: {
      "block": "",
      "color": "primary",
      "outlined": ""
    },
    on: {
      "click": function click($event) {
        return _vm.openLink('slack');
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    },
    domProps: {
      "textContent": _vm._s('mdi-slack')
    }
  }), _c('span', {
    staticClass: "font14"
  }, [_vm._v(" Join us on Slack ")])], 1)], 1), _c('v-col', {
    staticClass: "pt-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-btn', {
    staticClass: "text-transform-none",
    attrs: {
      "block": "",
      "color": "primary",
      "outlined": ""
    },
    on: {
      "click": function click($event) {
        return _vm.openLink('');
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    },
    domProps: {
      "textContent": _vm._s('mdi-linkedin')
    }
  }), _c('span', {
    staticClass: "font14"
  }, [_vm._v(" Follow us on LinkedIn ")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-divider')], 1), _c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('a', {
    staticClass: "mt-3 mr-4",
    attrs: {
      "href": "https://help.testapp.io/",
      "target": "_blank"
    }
  }, [_vm._v(" Help ")]), _c('span', {
    staticClass: "cursor-pointer primary--text",
    on: {
      "click": _vm.onContactus
    }
  }, [_vm._v("Contact us")]), _c('div', {
    staticClass: "mt-4"
  }, [_c('a', {
    staticClass: "mt-3 mr-4",
    attrs: {
      "href": "https://testapp.io/privacy-policy",
      "target": "_blank"
    }
  }, [_vm._v(" Privacy Policy ")]), _c('a', {
    attrs: {
      "href": "https://testapp.io/terms-and-conditions",
      "target": "_blank"
    }
  }, [_vm._v(" Terms & Conditions ")])]), _c('div', {
    staticClass: "text-center"
  }, [_c('small', [_vm._v(" Latest release: " + _vm._s(_vm.getLatestVersion) + " - "), _c('span', [_c('vue-hoverable-date', {
    attrs: {
      "date": _vm.getLatestReleaseTimestamp
    }
  })], 1)])])])], 1)], 1)], 1), _c('v-card', {
    staticClass: "transparent",
    attrs: {
      "flat": "",
      "tile": ""
    }
  }, [_c('SidePanel', {
    attrs: {
      "permanent": false,
      "temporary": "",
      "width": "600"
    },
    on: {
      "close": function close($event) {
        _vm.showWhatsNewList = false;
      }
    },
    model: {
      value: _vm.showWhatsNewList,
      callback: function callback($$v) {
        _vm.showWhatsNewList = $$v;
      },
      expression: "showWhatsNewList"
    }
  }, [_c('v-card-title', [_vm._v("What's new")]), _vm.showWhatsNewList ? _c('whats-new', {
    on: {
      "updateId": _vm.setNewWhatsNewId
    }
  }) : _vm._e()], 1), _c('Modal', {
    on: {
      "close": function close($event) {
        _vm.hideStartTrialModal = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "message",
      fn: function fn() {
        return [_c('v-row', [_c('v-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('div', {
          staticClass: "mt-4"
        }, [_c('v-checkbox', {
          attrs: {
            "value": _vm.startTrialAlert,
            "label": "Don't show this again"
          },
          on: {
            "change": function change($event) {
              return _vm.hideStartTrialAlert();
            }
          }
        })], 1)]), _c('v-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('v-btn', {
          staticClass: "text-transform-none",
          attrs: {
            "color": "primary",
            "depressed": ""
          },
          on: {
            "click": function click($event) {
              return _vm.hideStartTrialAlert('hideOnly');
            }
          }
        }, [_vm._v(" Hide ")])], 1)], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.hideStartTrialModal,
      callback: function callback($$v) {
        _vm.hideStartTrialModal = $$v;
      },
      expression: "hideStartTrialModal"
    }
  }), _c('Modal', {
    attrs: {
      "fullscreen": _vm.$vuetify.breakpoint.smAndDown,
      "width": _vm.$vuetify.breakpoint.smAndDown ? '' : 600,
      "noPy": ""
    },
    on: {
      "close": _vm.closeFeedbackForm
    },
    scopedSlots: _vm._u([{
      key: "message",
      fn: function fn() {
        return [_c('feedback-form', {
          attrs: {
            "flat": ""
          },
          on: {
            "close": function close($event) {
              _vm.showFeedbackForm = false;
            }
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.showFeedbackForm,
      callback: function callback($$v) {
        _vm.showFeedbackForm = $$v;
      },
      expression: "showFeedbackForm"
    }
  }), _c('Modal', {
    attrs: {
      "fullscreen": _vm.$vuetify.breakpoint.smAndDown
    },
    on: {
      "close": function close($event) {
        _vm.createTeamModal = false;
      }
    },
    scopedSlots: _vm._u([_vm.createTeamModal ? {
      key: "title",
      fn: function fn() {
        return [_c('v-spacer'), _c('div', {
          staticClass: "darkGrey--text"
        }, [_vm._v("Create new team")]), _c('v-spacer')];
      },
      proxy: true
    } : null, {
      key: "message",
      fn: function fn() {
        return [_c('create-team', {
          attrs: {
            "from-switch-team": ""
          },
          on: {
            "teamCreated": _vm.afterSuccess
          }
        })];
      },
      proxy: true
    }], null, true),
    model: {
      value: _vm.createTeamModal,
      callback: function callback($$v) {
        _vm.createTeamModal = $$v;
      },
      expression: "createTeamModal"
    }
  }), _c('Modal', {
    on: {
      "close": function close($event) {
        _vm.teamEditDialog = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "message",
      fn: function fn() {
        return [_vm.teamEditDialog ? _c('update-team-info', {
          on: {
            "close": _vm.closeUpdateDrawer
          }
        }) : _vm._e()];
      },
      proxy: true
    }]),
    model: {
      value: _vm.teamEditDialog,
      callback: function callback($$v) {
        _vm.teamEditDialog = $$v;
      },
      expression: "teamEditDialog"
    }
  }), _c('Modal', {
    attrs: {
      "fullscreen": _vm.$vuetify.breakpoint.smAndDown
    },
    scopedSlots: _vm._u([{
      key: "message",
      fn: function fn() {
        return [_c('v-card-title', {
          staticClass: "display-1 justify-center mb-4"
        }, [_vm._v(" Add App ")]), _c('v-card-text', [_vm.openApp ? _c('create-app-step', {
          attrs: {
            "from-create-panel": ""
          },
          on: {
            "close": function close($event) {
              _vm.openApp = false;
            }
          }
        }) : _vm._e()], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.openApp,
      callback: function callback($$v) {
        _vm.openApp = $$v;
      },
      expression: "openApp"
    }
  }), _c('Modal', {
    attrs: {
      "hideOverlay": ""
    },
    on: {
      "close": function close($event) {
        _vm.viewEditApp = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "message",
      fn: function fn() {
        return [_c('EditAppPanel', {
          attrs: {
            "app": _vm.appInfo
          },
          on: {
            "close": function close($event) {
              _vm.viewEditApp = false;
            },
            "success": function success($event) {
              return _vm.$emit('success', $event);
            }
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.viewEditApp,
      callback: function callback($$v) {
        _vm.viewEditApp = $$v;
      },
      expression: "viewEditApp"
    }
  }), _c('Modal', {
    attrs: {
      "no-py": ""
    },
    scopedSlots: _vm._u([{
      key: "card",
      fn: function fn() {
        return [_c('v-card', [_c('v-card-title', {
          staticClass: "font-weight-bold"
        }, [_vm._v("Delete app")]), _c('v-divider'), _c('v-card-text', {
          staticClass: "my-4 message"
        }, [_c('p', [_vm._v("Are you sure you want to delete this app?")]), _c('v-alert', {
          staticClass: "mt-3",
          attrs: {
            "border": "left",
            "color": "info",
            "icon": "info",
            "outlined": ""
          }
        }, [_vm._v(" You will lose the access to any information regarding this app. "), _vm.readMore ? _c('div', [_c('br'), _vm._v(" This app will be deleted along with everything attached to it such as:"), _c('br'), _c('br'), _c('ul', [_c('li', [_vm._v("Releases")]), _c('li', [_vm._v("Timeline")]), _c('li', [_vm._v("Notifications")])]), _c('br')]) : _vm._e(), !_vm.readMore ? _c('b', {
          staticClass: "pointer",
          on: {
            "click": function click($event) {
              _vm.readMore = !_vm.readMore;
            }
          }
        }, [_vm._v(" " + _vm._s("Read more...") + " ")]) : _vm._e(), _c('br')])], 1), _c('v-divider'), _c('v-card-actions', {
          staticClass: "py-3"
        }, [_c('div', [_vm._v("No undo for this action")]), _c('v-spacer'), _c('v-btn', {
          staticClass: "text-transform-none",
          attrs: {
            "id": "leaveAppCancelBtn",
            "color": "primary",
            "text": ""
          },
          on: {
            "click": _vm.onCancel
          }
        }, [_vm._v("No ")]), _c('v-btn', {
          staticClass: "text-transform-none",
          attrs: {
            "id": "leaveAppConfirmBtn",
            "loading": _vm.loading,
            "outlined": _vm.$vuetify.theme.dark,
            "color": "primary"
          },
          on: {
            "click": _vm.deleteAppConfirm
          }
        }, [_vm._v("Yes ")])], 1)], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.deleteModal,
      callback: function callback($$v) {
        _vm.deleteModal = $$v;
      },
      expression: "deleteModal"
    }
  }), _c('Modal', {
    attrs: {
      "no-py": ""
    },
    scopedSlots: _vm._u([{
      key: "card",
      fn: function fn() {
        return [_c('leave-team-modal', {
          attrs: {
            "flat": ""
          },
          on: {
            "close": _vm.onCancel
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.modal,
      callback: function callback($$v) {
        _vm.modal = $$v;
      },
      expression: "modal"
    }
  }), _c('Modal', {
    attrs: {
      "fullscreen": _vm.$vuetify.breakpoint.smAndDown,
      "width": _vm.$vuetify.breakpoint.mdAndUp ? 860 : '',
      "noPy": ""
    },
    on: {
      "close": function close($event) {
        _vm.showNotificationSetting = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "message",
      fn: function fn() {
        return [_vm.showNotificationSetting ? _c('manage-settings', {
          on: {
            "close": _vm.closeManageSettings
          }
        }) : _vm._e()];
      },
      proxy: true
    }]),
    model: {
      value: _vm.showNotificationSetting,
      callback: function callback($$v) {
        _vm.showNotificationSetting = $$v;
      },
      expression: "showNotificationSetting"
    }
  }), _c('Modal', {
    attrs: {
      "fullscreen": _vm.$vuetify.breakpoint.mdAndDown,
      "width": _vm.$vuetify.breakpoint.mdAndUp ? 984 : '',
      "no-py": ""
    },
    on: {
      "close": function close($event) {
        _vm.openTrialModal = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "message",
      fn: function fn() {
        return [_c('start-trial-modal', {
          attrs: {
            "from-menu": ""
          },
          on: {
            "close": function close($event) {
              _vm.openTrialModal = false;
            }
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.openTrialModal,
      callback: function callback($$v) {
        _vm.openTrialModal = $$v;
      },
      expression: "openTrialModal"
    }
  }), _c('Modal', {
    attrs: {
      "fullscreen": _vm.$vuetify.breakpoint.smAndDown,
      "width": _vm.$vuetify.breakpoint.mdAndUp ? '600' : '',
      "noPy": ""
    },
    on: {
      "close": function close($event) {
        _vm.editUserProfile = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "card",
      fn: function fn() {
        return [_vm.editUserProfile ? _c('v-card', {
          staticClass: "pa-2",
          attrs: {
            "flat": ""
          }
        }, [_c('v-card-title', {
          staticClass: "bold-text bodyFont",
          attrs: {
            "cols": "12"
          }
        }, [_c('div', [_vm._v("Edit profile")]), _c('v-spacer'), _c('v-btn', {
          attrs: {
            "icon": ""
          },
          on: {
            "click": function click($event) {
              _vm.editUserProfile = false;
            }
          }
        }, [_c('v-icon', {
          domProps: {
            "textContent": _vm._s('mdi-close')
          }
        })], 1)], 1), _c('v-card-text', [_vm.editUserProfile ? _c('edit-profile', {
          staticClass: "my-4",
          on: {
            "close": function close($event) {
              _vm.editUserProfile = false;
            }
          }
        }) : _vm._e()], 1)], 1) : _vm._e()];
      },
      proxy: true
    }]),
    model: {
      value: _vm.editUserProfile,
      callback: function callback($$v) {
        _vm.editUserProfile = $$v;
      },
      expression: "editUserProfile"
    }
  }), _c('Modal', {
    on: {
      "close": function close($event) {
        _vm.appStoreLinkModal = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "card",
      fn: function fn() {
        return [_vm.appStoreLinkModal ? _c('download-app-modal', {
          attrs: {
            "store-link": _vm.storeLink
          },
          on: {
            "close": function close($event) {
              _vm.appStoreLinkModal = false;
            }
          }
        }) : _vm._e()];
      },
      proxy: true
    }]),
    model: {
      value: _vm.appStoreLinkModal,
      callback: function callback($$v) {
        _vm.appStoreLinkModal = $$v;
      },
      expression: "appStoreLinkModal"
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }